import { useEffect, useRef } from "react";
import { isClient } from "@/utils";

const useOutsideClick = (callback: any, numberOfElements = 1) => {
    const refs = useRef<Array<HTMLDivElement | null>>(
        Array.from({ length: numberOfElements })
    );

    useEffect(() => {
        const handleClick = (event: any) => {
            // if event is outside of all the refs then call the callback
            if (
                refs.current.every((ref) => ref && !ref.contains(event.target))
            ) {
                callback();
            }

            // let outsideRef = false;

            // for (let i = 0; i < numberOfElements; i++) {
            //     if (
            //         refs.current[i] &&
            //         !refs.current[i]?.contains(event.target)
            //     ) {
            //         outsideRef = true;
            //     }
            // }

            // if (outsideRef) {
            //     callback();
            // }
        };

        if(isClient()){
            document.addEventListener("click", handleClick, true);
            
            return () => {
                    document.removeEventListener("click", handleClick, true);
            };
        }

      
    }, [refs, callback]);

    return refs;
};

export default useOutsideClick;
