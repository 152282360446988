import { useEffect, useState } from "react";

const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
        .toString()
        .padStart(2, "0");

    const seconds = (time % 60).toString().padStart(2, "0");

    const formattedTime = `${minutes}:${seconds}`;

    return { formattedTime, seconds };
};

const useStopWatch = () => {
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const resetTimer = () => {
        setElapsedTime(0);
    };

    const time = formatTime(elapsedTime);

    const { formattedTime, seconds } = time;

    return { formattedTime, seconds, totalSeconds: elapsedTime, resetTimer };
};

export default useStopWatch;
