import React, { useState } from "react";
import { useBreakpoint } from "@/hooks";
import { getImageUrl } from "@/utils";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const testimonials = [
    {
        image: getImageUrl("/static/Testimonial_Images/Shanmukh_Comp.jpg"),
        name: "Shanmukha Rao Pydi",
        details: "IIM Ahmedabad, Batch of 2024",
        message:
            "I had taken CAT 2020 without much prep and got 60 %ile. I was fortunate to find mockat.com and the people behind it - Vignesh Sir and Sanjana Ma'am. I highly recommend everything here - Online Classes, Video Lessons, Mock Tests and Interview Prep which helped me get 99+ %ile in CAT 2021 and prepared me for the interviews to join my dream college - IIM Ahmedabad. Would hands down recommend them.",
    },
    {
        image: getImageUrl("/static/Testimonial_Images/Harika_Comp.jpg"),
        name: "Harika D",
        details: "IIM Calcutta, Batch of 2024",
        message:
            "As a working professional with a Biotech background, I found Mockat easy to use and convenient to learn from. I found the Quant content to be very useful as it's covered from the basics and conceptually. The Concept Revision videos were helpful to balance my work and prep.  I've tried mocks in many places, and the mocks here are realistic and close to CAT. Their personalised feedback helped me get 99+ %ile and join IIM Calcutta.",
    },
    {
        image: getImageUrl("/static/Testimonial_Images/Sreevardhan_Comp.jpg"),
        name: "Sai Sreevardhan",
        details: "Masters' Union, Batch of 2023",
        message:
            "I joined Mockat’s Classroom prep pack. Their video lessons and online classes are very good, and ensured that we're clear with the concepts. They always stayed back in every class till every student's doubts were clarified. They really went out of their way in helping me with the forms and interview prep. Definitely recommend them.",
    },
    {
        image: getImageUrl("/static/Testimonial_Images/Hanuram_Comp.jpg"),
        name: "Hanuram T",
        details: "IIM Calcutta, Batch of 2021",
        message:
            "Vignesh & Sanjana are very good in their respective sections – I have yet to see them fazed and unable to solve any problem. They have the structure and methodology of each exam down to a science and they will work with you to ensure that you tackle each exam the right way and maximise your score. I personally feel that the WAT/GD/PI training is the highlight as Vignesh is extremely good at this.",
    },
    {
        image: getImageUrl("/static/Testimonial_Images/Kumari_Comp.jpg"),
        name: "Kumari Prakash",
        details: "IIM Lucknow, Batch of 2024",
        message:
            "I spoke to an Educational Counsellor who guided me very well, and the free content was also useful to help me sign up confidently. I watched all the lesson videos & attended all their live classes online. The Practice questions helped me to get my concepts clear. I solved every Mock and Sectional, and they always guided us on how to do better. Overall, I would highly recommend Mockat's Classroom Prep to every student who is preparing for MBA. Thank you, Sir & Mam! ",
    },
    {
        image: getImageUrl("/static/Testimonial_Images/Deepika_Comp.jpg"),
        name: "Deepika Sekar",
        details: "IIM Kozhikode, Batch of 2023",
        message:
            "I joined Mockat after completing my engineering degree. I had only a few months to prepare, and was keen to pursue an MBA from a top B-school. Now I recommend Mockat to everyone who wants to crack CAT, including my younger sister, who's signed up at Mockat too! I would totally credit them with getting through IIM Lucknow & IIM Kozhikode, that too as a fresher and an engineer.",
    },
    {
        image: getImageUrl("/static/Testimonial_Images/Anand_Comp.jpg"),
        name: "Anandhan M",
        details: "NMIMS Mumbai, Batch of 2022",
        message:
            "Being a National medallist in swimming, I had the ambition to get through a top B-school but had not focused much on studies. Vignesh sir and Sanjana mam literally taught me everything for CAT, NMAT, etc. Inspired by Vignesh sir's experience as the Student Council President at IIML, I tried out for the Student Council at NMIMS, got through and also got placed in a Leadership role after graduating.",
    },
    {
        image: getImageUrl(
            "/static/Testimonial_Images/Jyothikrishnan_Comp.jpg"
        ),
        name: "Jyothikrishnan",
        details: "NITIE, Batch of 2022",
        message:
            "I would never have got through NITIE without the Mockat family! Sir motivated me to give this my best shot and push myself along with my work at Ford Motors. The personalised coaching at Mockat is at a totally different level. The content, live classes, mocks and interview guidance are all excellent. Thanks to them, I got through NITIE and had a great experience on campus.",
    },
    {
        image: getImageUrl("/static/Testimonial_Images/Harish_Comp.jpg"),
        name: "Harish Kumar",
        details: "IIM Bangalore, Batch of 2022",
        message:
            "I joined Mockat for interview preparation and got through IIM Bangalore. I did not know that there is so much that goes into an interview, till I joined Mockat. Vignesh sir made me understand that it is important to present myself genuinely, and that's what helped me clear IIMB. Sir's knowledge of current affairs is immense, and I still visit mockat.com for the daily news!",
    },
];

const Slider = () => {
    const [swiper, setSwiper] = useState(null);
    const breakpoint = useBreakpoint();

    const slideNext = () => {
        if (swiper) {
            // @ts-ignore
            swiper.slideNext();
        }
    };

    const slidePrev = () => {
        if (swiper) {
            // @ts-ignore

            swiper.slidePrev();
        }
    };

    // @ts-ignore
    const handleSwiperInit = (swiper) => {
        setSwiper(swiper);
    };

    return (
        <div className="w-full px-4 pb-24 font-Inter md:mb-6 md:mt-12 md:px-0 md:pb-32">
            <p className="my-4 w-full text-center text-45 font-semibold leading-55 text-white md:my-0 md:mb-16">
                Testimonials
            </p>
            <Swiper
                className="!overflow-y-visible pb-20"
                loop={true}
                slidesPerView={breakpoint === "lg" && "2md" ? 2 : 1}
                spaceBetween={40}
                slidesOffsetBefore={0}
                centeredSlides={true}
                modules={[Navigation]}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                onSwiper={handleSwiperInit}
            >
                {testimonials.map((item, index) => (
                    <SwiperSlide key={index} className="relative h-max w-max">
                        <div className="relative rounded-26 bg-gradient-to-r from-blue-13 to-green-16 p-5">
                            <div className="flex w-full flex-col items-center justify-center">
                                <img
                                    src={item.image}
                                    alt="avatar"
                                    width={90}
                                    height={90}
                                />
                                <p className="mt-1 font-sans text-lg font-bold text-blue-14">
                                    {item.name}
                                </p>
                                <p className="mt-1 w-full text-center text-sm font-normal text-black">
                                    {item.details}
                                </p>
                                <div className="mt-4 flex items-start px-6">
                                    <img
                                        src={getImageUrl("/images/quote.svg")}
                                        alt="Quote Icon"
                                        width={25}
                                        height={25}
                                        className="relative mr-2"
                                    />
                                    <p className="mt-2 text-center text-13 font-normal">
                                        {item.message}
                                    </p>
                                    <img
                                        src={getImageUrl("/images/quote.svg")}
                                        alt="Quote Icon"
                                        width={25}
                                        height={25}
                                        className="ml-2 rotate-180 self-end"
                                    />
                                </div>
                                <div className="mb-4 mt-2 flex items-center justify-center">
                                    {Array.from({ length: 5 })?.map(
                                        (ele, i) => {
                                            return (
                                                <img
                                                    src={getImageUrl(
                                                        "/images/star.svg"
                                                    )}
                                                    width={16}
                                                    height={16}
                                                    alt="star"
                                                    className="mx-1 h-4 w-4"
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
                <div
                    onClick={slidePrev}
                    className="swiper-button-prev !left-[30%] !top-[110%] after:!content-none md:!left-[43%] md:!top-[110%]"
                >
                    <img
                        src={getImageUrl("/images/slides-navigation-icon.svg")}
                        width={32}
                        height={30}
                        alt=""
                        className="max-w-none"
                    />
                </div>
                <div
                    onClick={slideNext}
                    className="swiper-button-next !right-[30%] !top-[110%] after:!content-none md:!right-[43%] md:!top-[110%]"
                >
                    <img
                        src={getImageUrl("/images/slides-navigation-icon.svg")}
                        height={32}
                        alt=""
                        width={32}
                        className="max-w-none rotate-180"
                    />
                </div>
            </Swiper>
        </div>
    );
};

export default Slider;
