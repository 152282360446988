import React from "react";
import { getImageUrl } from "@/utils";
import Link from "next/link";

const dailyData = [
    {
        icon: getImageUrl("/images/newspaper.svg"),
        title: "PoV",
        link: "/daily/daily-pov",
        bg: getImageUrl("/images/daily-pov-bg.png"),
        borderColor: "bg-[#C8E1FF]",
    },
    {
        icon: getImageUrl("/images/books.svg"),
        title: "NEWS",
        link: "/daily/daily-news",
        bg: getImageUrl("/images/dailyNews-bg.png"),
        borderColor: "bg-[#DECEEE]",
    },
    {
        icon: getImageUrl("/images/brain.svg"),
        title: "QUIZ",
        link: "/daily/daily-quiz",
        bg: getImageUrl("/images/daily-quiz-bg.png"),
        borderColor: "bg-[#B3E1DD]",
    },
];

const DailyModulesLinks = () => {
    return (
        <div className="min-w-full rounded-2xl bg-gray-2 px-2 py-6 font-Inter text-blue-3 md:py-3 md:pt-6 2md:px-4 xl:px-14">
            <div className="flex w-full flex-col items-center justify-between md:py-6 2md:flex-col xl:flex-row">
                <div className="flex w-full text-xl 2md:w-full 2md:justify-center xl:w-2/5 xl:justify-start">
                    <div className="block w-full justify-between md:mr-14 2md:ml-6 2md:flex xl:ml-0 xl:block">
                        <p className="text-[30px] font-bold md:my-1 md:text-6xl 2md:mr-12 xl:mr-0 xl:leading-72 ">
                            Daily
                        </p>
                        <p className="flex w-full flex-col items-center justify-start font-bold md:text-3xl xl:items-start xl:justify-start">
                            <span className="mr-8 text-[26px]">
                                Start your CAT preparation now
                            </span>
                            <span className="text-[15px] 2md:block 2md:text-[26px]">
                                using our curated daily modules.
                            </span>
                            {/* <span className="ml-4 text-[33px] font-bold md:ml-0 md:text-67 md:leading-81">
                                80%
                            </span> */}
                        </p>
                    </div>
                </div>
                <div className="mt-6 flex flex-row flex-wrap items-center justify-center gap-3 md:flex-row md:justify-between 2md:mt-6 2md:w-full 2md:flex-wrap lg:w-[70%] xl:mt-0 xl:w-3/5 xl:flex-nowrap">
                    {dailyData.map((item, index) => {
                        return (
                            <Link href={item.link} key={index}>
                                <div
                                    className={`flex-col rounded-3xl ${item.borderColor} p-2 shadow-xl`}
                                >
                                    <div className="relative flex justify-center">
                                        <img
                                            src={item.bg}
                                            width={140}
                                            height={146}
                                            className="h-[146px] w-[140px] md:h-auto md:w-[180px] md:max-w-none"
                                            alt=""
                                        />
                                        <img
                                            src={getImageUrl(
                                                "/images/arrow-blue.svg"
                                            )}
                                            alt="Arrow Right"
                                            className="absolute right-2 ml-2 md:right-4 md:top-2 "
                                            width={30}
                                            height={30}
                                        />
                                        <div className="absolute left-4 top-4 p-4 md:w-167">
                                            <div className="flex flex-col justify-between">
                                                <img
                                                    src={item.icon}
                                                    alt=""
                                                    width={25}
                                                    height={25}
                                                    className="mb-6"
                                                />
                                                <p className="mb-3 font-Inter text-15 font-bold leading-18 text-blue-3">
                                                    DAILY <br />{" "}
                                                    <span className="text-xl font-bold leading-23 ">
                                                        {" "}
                                                        {item.title}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default DailyModulesLinks;
