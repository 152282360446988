/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, useState, useContext } from "react";
import { Sidebar } from "@/components/Layout/components";
import { useCurrentUser } from "@/context/currentUser";
import ModalsContext from "@/context/modals";
import PackageSubscriptionContext from "@/context/packagesubscription";
import useOutsideClick from "@/hooks/useOutsideClick";
import { getImageUrl } from "@/utils";
import { Dialog } from "@headlessui/react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { useRouter } from "next/router";
import routes from "routes";
import NavbarPopper from "./NavbarPopper";

type Props = {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const Navbar = ({ visible, setVisible }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const { setLoginOpen } = useContext(ModalsContext);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const router = useRouter();

    const toggleSidebar = () => {
        setVisible((prevState) => !prevState);
        setIsSidebarOpen((prevState) => !prevState);
    };

    const { firstName, avatar, isAdmin, isLoggedIn, handleLogout } =
        useCurrentUser();

    const handleClick = () => {
        if (isLoggedIn) {
            router.push("/dashboard");
        } else {
            setLoginOpen(true);
        }
    };

    const handleClickOutside = () => {
        if (!isSidebarOpen) return;
        setIsSidebarOpen(false);
        setVisible(false);
    };

    const refs = useOutsideClick(handleClickOutside);

    const sideBarWidth = isSidebarOpen ? "w-72" : "w-20";

    return (
        <div className="w-full">
            <div
                className={`${
                    visible ? "fixed top-0" : "relative"
                } z-[100] flex w-full items-center justify-between bg-primary-1`}
            >
                <div className="flex items-center md:px-4">
                    <div
                        className="block  md:hidden"
                        ref={(el) => (refs.current[0] = el)}
                    >
                        <Bars3Icon
                            className="ml-4 block h-8 w-8 cursor-pointer text-white lg:hidden"
                            onClick={toggleSidebar}
                        />
                    </div>
                    <img
                        src={getImageUrl("/images/logo.png")}
                        width={187}
                        height={40}
                        alt=""
                        className="ml-1  w-[195px] py-3 md:h-20 md:w-[260px]"
                    />
                </div>

                <div className=" flex w-full items-center justify-end">
                    <div className="hidden md:flex">
                        {isAdmin ? (
                            <Link href="/admin">
                                <p className="mx-4 mr-20 flex cursor-pointer items-center justify-center  text-xl font-semibold text-white">
                                    Admin
                                </p>
                            </Link>
                        ) : (
                            <Fragment>
                                <Link href="/dashboard/snapshot">
                                    <p className="mx-4 flex cursor-pointer items-center justify-center  text-xl font-semibold text-white">
                                        Dashboard
                                    </p>
                                </Link>
                                <Link href="/learn/quantitative-ability">
                                    <p
                                        onMouseEnter={() => setIsOpen(true)}
                                        className="mx-4 flex cursor-pointer items-center justify-center  text-xl font-semibold text-white"
                                    >
                                        Prep Zone
                                    </p>
                                </Link>
                                <Link href={routes.infozone()}>
                                    <p className="mx-4 flex cursor-pointer items-center justify-center  text-xl font-semibold text-white">
                                        Blogs
                                    </p>
                                </Link>
                                <Link href="/plans">
                                    <p className="mx-4 mr-16 flex cursor-pointer items-center justify-center  text-xl font-semibold text-white">
                                        Plans
                                    </p>
                                </Link>
                            </Fragment>
                        )}
                    </div>

                    <div className="slanted-rectangle-2 relative top-[6px] mt-2 flex h-full max-h-[65px] min-h-[65px] min-w-[144.75px] items-center justify-center  rounded-tl-lg bg-white pb-5 pt-2 md:rounded-tl-lg md:rounded-tr-xl">
                        {isLoggedIn ? (
                            <div className="relative right-2 top-1 mx-2 flex items-center rounded-3xl px-4 py-1 text-xs font-semibold text-primary-1">
                                <Popover className="relative z-[10000]">
                                    {() => (
                                        <>
                                            <Popover.Button className="outline-none focus:outline-none">
                                                <div className="relative min-h-[40px] min-w-[60px]">
                                                    <div className="absolute -top-[8px] right-0 flex flex-col items-center">
                                                        <img
                                                            src={
                                                                avatar ||
                                                                getImageUrl(
                                                                    "/images/avatar.svg"
                                                                )
                                                            }
                                                            width={60}
                                                            height={60}
                                                            alt=""
                                                            className="h-12 w-12"
                                                        />
                                                        <p className="mb-2 w-max text-center text-primary-1">
                                                            {isAdmin
                                                                ? "Admin"
                                                                : firstName}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Popover.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel className="absolute left-0 z-10 mt-3 max-w-sm -translate-x-1/2 transform  px-5 py-2  sm:px-0 lg:max-w-3xl">
                                                    <div className="flex flex-col bg-gray-2 py-2">
                                                        {!isAdmin && (
                                                            <button
                                                                onClick={() => {
                                                                    router.push(
                                                                        "/profile"
                                                                    );
                                                                }}
                                                                className="mx-4 mb-2 w-28 rounded bg-blue-3 px-4 py-2 text-center  text-sm font-semibold text-white"
                                                            >
                                                                Edit Profile
                                                            </button>
                                                        )}

                                                        <button
                                                            onClick={() => {
                                                                handleLogout();
                                                            }}
                                                            className="mx-4 mb-2 w-28 rounded bg-blue-3 px-4 py-2 text-center  text-sm font-semibold text-white"
                                                        >
                                                            Logout
                                                        </button>
                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                            </div>
                        ) : (
                            <button
                                onClick={handleClick}
                                className="relative right-2 top-2 mx-3 flex h-max items-center justify-center rounded-3xl bg-[#DFE9FD] px-4 py-1 text-xs font-semibold text-primary-1"
                            >
                                <Fragment>
                                    <img
                                        src={getImageUrl("/images/person.svg")}
                                        alt=""
                                        width={20}
                                        height={20}
                                        className="mr-1"
                                    />
                                    <p className="px-2 text-lg font-bold">
                                        Login
                                    </p>
                                </Fragment>
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="">
                <Dialog.Panel onMouseLeave={() => setIsOpen(false)}>
                    <NavbarPopper />
                </Dialog.Panel>
            </Dialog>
            <div
                // @ts-ignore
                ref={
                    // @ts-ignore
                    (el) => (refs.current[1] = el)
                }
                className="fixed bottom-0 top-12 z-[99] h-[100vh] md:hidden"
            >
                <Sidebar
                    visible={visible}
                    isOpen={isSidebarOpen}
                    setIsOpen={setIsSidebarOpen}
                    sideBarWidth={sideBarWidth}
                />
            </div>
        </div>
    );
};

export default Navbar;
