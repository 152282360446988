import React from "react";
import { getImageUrl } from "@/utils";
import Link from "next/link";

const FloatingLinks = () => {
    return (
        <div className="fixed bottom-36 right-6 z-50 flex flex-col gap-1">
            <Link
                target="_blank"
                rel="noopener noreferrer"
                href={"https://www.youtube.com/@Mockat"}
            >
                {" "}
                <img
                    src={getImageUrl("/images%2Fyoutube.png")}
                    alt=""
                    className="w-10"
                />
            </Link>
            <Link
                target="_blank"
                rel="noopener noreferrer"
                href={"https://t.me/crackcatwithiimlgrads"}
            >
                <img
                    src={getImageUrl("/images%2Ftelegram.png")}
                    alt=""
                    className="w-10"
                />
            </Link>
            <Link
                target="_blank"
                rel="noopener noreferrer"
                href={"https://wa.me/9600121800"}
            >
                <img
                    src={getImageUrl("/images%2Fwhatsapp.png")}
                    alt=""
                    className="w-10"
                />
            </Link>
        </div>
    );
};

export default FloatingLinks;
