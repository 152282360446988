import getSuperDomain from "@/modules/learn/getSuperDomain";
import { useRouter } from "next/router";
import routes from "routes";

const useCurrentSuperDomainFromSlug = () => {
    const router = useRouter();

    const superDomain = getSuperDomain();

    const currentTab = superDomain;

    const setCurrentTab = (slug: string) => {
        router.push(
            routes.learn({
                superDomain: slug,
                isFree: router.route.includes("/free"),
            }).href
        );
    };

    return { currentTab, setCurrentTab };
};

export default useCurrentSuperDomainFromSlug;
