import { useRouter } from "next/router";


const getSuperDomain = () => {
    const router = useRouter();

    let superDomain =
        (router.query.superDomain as string) ||
        (router.query.super_domain as string);

    if (window.location.pathname == "/live-classes") {
        superDomain = "live-classes";
    } else if (window.location.pathname == "/quantitative-ability") {
        superDomain = "quantitative-ability";
    } else if (window.location.pathname == "/verbal-ability") {
        superDomain = "verbal-ability";
    } else if (window.location.pathname == "/di-lr") {
        superDomain = "di-lr";
    } else if (window.location.pathname == "/learn") {
        superDomain = "quantitative-ability";
    }

    return superDomain ?? "quantitative-ability";
};

export default getSuperDomain;