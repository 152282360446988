import React from "react";
import { getImageUrl } from "@/utils";

const Coaches = () => {
    const tutorOne = getImageUrl("/static/tutor-one-down.png");

    const tutorTwo = getImageUrl("/static/tutor-two-down.png");

    return (
        <div className="flex flex-col bg-white px-2 2md:px-2 2md:py-2 xl:px-24 xl:py-16">
            <p className="mb-2 w-full text-center text-[33px] font-bold text-primary-1 md:text-4xl  2md:mt-4">
                Meet your Coaches
            </p>
            <p className="w-full text-center text-base font-semibold text-primary-2 md:text-xl 2md:mb-4 xl:mb-12">
                The only place with all classes by 99.9+ %ilers
            </p>
            <div className="relative my-8 hidden items-center overflow-hidden rounded-md bg-primary-1 py-6 xl:flex">
                <div className="z-50 md:w-1/3">
                    <img
                        src={tutorOne}
                        width={150}
                        height={100}
                        alt="Vignesh"
                        className="absolute -bottom-6 -left-7 w-[250px] self-end md:-bottom-10 md:left-auto  md:h-auto md:w-[455px] md:!max-w-full"
                    />
                </div>
                <div className="flex h-full w-full flex-col px-8 text-white md:w-2/3 md:px-16">
                    <p className="tex-sm mb-2 pl-2 font-bold md:text-4xl">
                        Vignesh Srinivasan
                    </p>
                    <p className="mb-4 text-[8px] md:pl-2 md:text-xl">
                        CAT (Quant) - 99.99%ile CAT (Overall) - 99.98%ile
                    </p>
                    <div className="relative flex h-full w-full overflow-hidden">
                        <img
                            src={getImageUrl("/images/coaches-bg.png")}
                            alt=""
                            className="h-[285px] w-full"
                        />
                        <p className="absolute top-4 w-[85%] transform text-10 font-medium text-black md:left-1/2 md:-translate-x-1/2 md:text-xl m2xl:top-6">
                            {/* 
                                Quant & DILR Sections of CAT and coaches
                                students for the same.
                            </span>{" "} */}
                            Meet Vignesh Sir: An exceptional commerce graduate
                            who masters the{" "}
                            <span className="font-bold">Quant & DILR</span>{" "}
                            sections of the CAT. He coaches students to find
                            their answers faster and better, enhancing their
                            logical thinking, question approach, and test-taking
                            strategies. His innovative{" "}
                            <span className="font-bold">6-8-8 strategy</span>{" "}
                            has proven invaluable, significantly improving many
                            students' performances in Quant.
                        </p>
                    </div>
                </div>
            </div>

            <div className="relative my-8 hidden items-center overflow-hidden rounded-md bg-primary-1 py-6 xl:flex">
                <div className="z-50 md:w-1/3">
                    <img
                        src={tutorTwo}
                        height={100}
                        alt="Sanjana"
                        width={455}
                        className="absolute -bottom-6 -left-7 w-[250px] self-end md:-bottom-10 md:left-auto  md:h-auto md:w-[455px] md:!max-w-full"
                    />
                </div>
                <div className="flex flex-col text-white md:w-2/3 md:px-16">
                    <p className="mb-2 pl-2 text-sm font-bold md:text-4xl">
                        Sanjana Pani
                    </p>
                    <p className="mb-4 pl-2 text-[7px] md:text-xl">
                        CAT (Verbal) - 99.97%ile XAT (Overall) - 99.98% ile
                    </p>
                    <div className="relative overflow-hidden">
                        <img
                            src={getImageUrl("/images/coaches-bg.png")}
                            alt=""
                            className="h-[285px] w-full"
                        />
                        <p className="absolute left-1/2 top-4 w-[85%] -translate-x-1/2 transform text-10 text-xl font-medium text-black m2xl:top-6">
                            Meet Sanjana Ma'am: A{" "}
                            <span className="font-bold">Verbal</span> Section
                            Expert in CAT, XAT, and other MBA entrance exams.
                            She equips her students with advanced reading and
                            problem-solving strategies, ensuring daily practice
                            and interactive guided sessions. Her innovative{" "}
                            <span className="font-bold">
                                'Author's Point of View'
                            </span>{" "}
                            method has significantly boosted Verbal scores for
                            numerous students, helping them excel in their MBA
                            entrance tests.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 flex h-max w-full flex-col items-center rounded-md bg-primary-1  md:hidden">
                <div className="z-50">
                    <div className=" flex flex-row ">
                        <div className="relative top-2 w-1/2 scale-125">
                            <img
                                src={tutorOne}
                                width={150}
                                height={100}
                                alt="Vignesh"
                                className="w-[250px] "
                            />
                        </div>
                        <div className="flex w-1/2 flex-col justify-end">
                            <p className="tex-sm mb-2 pl-2 font-bold text-white md:text-4xl">
                                Vignesh Srinivasan
                            </p>
                            <p className="mb-4 text-[8px] text-white md:pl-2 md:text-xl">
                                CAT (Quant) - 99.99%ile CAT (Overall) -
                                99.98%ile
                            </p>
                        </div>
                    </div>

                    <div className="relative flex  overflow-hidden">
                        <img
                            src={getImageUrl("/images/coaches-bg.png")}
                            alt=""
                            className="ml-4 h-[135px] w-[340px]"
                        />
                        <div className="absolute left-0 w-[380px] px-10 pt-1">
                            <p className="text-10 font-medium text-black ">
                                Meet Vignesh Sir: An exceptional commerce
                                graduate who masters the{" "}
                                <span className="font-bold">Quant & DILR</span>{" "}
                                sections of the CAT. He coaches students to find
                                their answers faster and better, enhancing their
                                logical thinking, question approach, and
                                test-taking strategies. His innovative{" "}
                                <span className="font-bold">
                                    6-8-8 strategy
                                </span>{" "}
                                has proven invaluable, significantly improving
                                many students' performances in Quant.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-2 flex h-max w-full flex-col items-center rounded-md bg-primary-1  md:hidden">
                <div className="z-50">
                    <div className=" flex flex-row ">
                        <div className="relative top-2 w-1/2 scale-125">
                            <img
                                src={tutorTwo}
                                width={150}
                                height={100}
                                alt="Sanjana"
                                className=" w-[250px] "
                            />
                        </div>
                        <div className="flex w-1/2 flex-col justify-end">
                            <p className="tex-sm mb-2 pl-2 font-bold text-white md:text-4xl">
                                Sanjana Pani
                            </p>
                            <p className="mb-4 text-[8px] text-white md:pl-2 md:text-xl">
                                CAT (Verbal) - 99.97%ile XAT (Overall) - 99.98%
                                ile
                            </p>
                        </div>
                    </div>

                    <div className="relative flex  overflow-hidden">
                        <img
                            src={getImageUrl("/images/coaches-bg.png")}
                            alt=""
                            className="ml-4 h-[135px] w-[340px]"
                        />
                        <div className="absolute left-0 w-[385px] px-10 pt-1">
                            <p className="text-10 font-medium text-black ">
                                Meet Sanjana Ma'am: A{" "}
                                <span className="font-bold">Verbal</span>{" "}
                                Section Expert in CAT, XAT, and other MBA
                                entrance exams. She equips her students with
                                advanced reading and problem-solving strategies,
                                ensuring daily practice and interactive guided
                                sessions. Her innovative{" "}
                                <span className="font-bold">
                                    'Author's Point of View'
                                </span>{" "}
                                method has significantly boosted Verbal scores
                                for numerous students, helping them excel in
                                their MBA entrance tests.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden w-full justify-between 2md:flex xl:hidden">
                <div className="my-2 flex h-max w-[49%] flex-col items-center rounded-md bg-primary-1 pb-2 ">
                    <div className="z-50">
                        <div className=" flex flex-row ">
                            <div className="relative top-2 w-1/2 scale-125">
                                <img
                                    src={tutorOne}
                                    height={100}
                                    width={150}
                                    alt="Vignesh"
                                    className="w-[250px] "
                                />
                            </div>
                            <div className="flex w-1/2 flex-col justify-end">
                                <p className="tex-sm mb-2 pl-2 font-bold text-white ">
                                    Vignesh Srinivasan
                                </p>
                                <p className="mb-4 text-[8px] text-white ">
                                    CAT (Quant) - 99.99%ile CAT (Overall) -
                                    99.98%ile
                                </p>
                            </div>
                        </div>

                        <div className="relative flex  overflow-hidden">
                            <img
                                src={getImageUrl("/images/coaches-bg.png")}
                                alt=""
                                className="ml-4 h-[130px] w-[370px]"
                            />
                            <div className="absolute left-0 w-[400px] px-10 pt-1">
                                <p className="text-10 font-medium text-black ">
                                    Meet Vignesh Sir: An exceptional commerce
                                    graduate who masters the{" "}
                                    <span className="font-bold">
                                        Quant & DILR
                                    </span>
                                    sections of the CAT. He coaches students to
                                    find their answers faster and better,
                                    enhancing their logical thinking, question
                                    approach, and test-taking strategies. His
                                    innovative{" "}
                                    <span className="font-bold">
                                        6-8-8 strategy
                                    </span>{" "}
                                    has proven invaluable, significantly
                                    improving many students' performances in
                                    Quant.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-2 flex h-max w-[49%] flex-col items-center rounded-md bg-primary-1 pb-2">
                    <div className="z-50">
                        <div className=" flex flex-row ">
                            <div className="relative top-2 w-1/2 scale-125">
                                <img
                                    src={tutorTwo}
                                    height={100}
                                    width={150}
                                    alt="Sanjana"
                                    className=" w-[250px] "
                                />
                            </div>
                            <div className="flex w-1/2 flex-col justify-end">
                                <p className="tex-sm mb-2 pl-2 font-bold text-white ">
                                    Sanjana Pani
                                </p>
                                <p className="mb-4 text-[8px] text-white ">
                                    CAT (Verbal) - 99.97%ile XAT (Overall) -
                                    99.98% ile
                                </p>
                            </div>
                        </div>

                        <div className="relative flex overflow-hidden">
                            <img
                                src={getImageUrl("/images/coaches-bg.png")}
                                alt=""
                                className="ml-4 h-[130px] w-[370px]"
                            />
                            <div className="absolute bottom-6 left-0 w-[400px] px-10 pt-1">
                                <p className="text-10 font-medium text-black ">
                                    Meet Sanjana Ma'am: A{" "}
                                    <span className="font-bold">Verbal</span>{" "}
                                    Section Expert in CAT, XAT, and other MBA
                                    entrance exams. She equips her students with
                                    advanced reading and problem-solving
                                    strategies, ensuring daily practice and
                                    interactive guided sessions. Her innovative
                                    <span className="font-bold">
                                        'Author's Point of View'
                                    </span>{" "}
                                    method has significantly boosted Verbal
                                    scores for numerous students, helping them
                                    excel in their MBA entrance tests.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Coaches;
