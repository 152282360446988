import React, { Fragment } from "react";
import { getImageUrl } from "@/utils";
import { MessageCircle } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";

const Footer = () => {
    const router = useRouter();

    const isActiveLink = (href: string) => {
        return router.pathname === href ? "text-primary-2" : "";
    };

    const email = "info@mockat.com";
    // const subject = encodeURIComponent("Your");
    // const body = encodeURIComponent("Your Email Content Here");

    return (
        <Fragment>
            <div
                style={{
                    backgroundImage: "url('images/footer-bg.jpg')",
                }}
                className="flex h-fit w-full  flex-wrap items-stretch justify-center bg-cover bg-scroll bg-no-repeat p-4  md:flex-nowrap md:justify-between md:p-20"
            >
                <div className="w-full md:!min-h-full md:w-1/3">
                    <div>
                        <img
                            src={getImageUrl("/images/logo.png")}
                            width={260}
                            height={100}
                            alt=""
                        />
                    </div>
                    <p className="mt-4 w-4/5 font-medium text-[#CCCCCC]">
                        Founded by two IIM Lucknow grads to provide high quality
                        Prep for MBA aspirants
                    </p>
                </div>
                <div className="w-full md:w-1/3">
                    <p className="mb-3 text-center text-2xl font-semibold text-primary-2">
                        Quick Links
                    </p>
                    <div className="flex items-start justify-around">
                        <div className="flex flex-col text-lg text-white">
                            <p className="mb-4 font-bold text-primary-2">
                                Prep Zone
                            </p>
                            <Link
                                href={"/learn"}
                                className="mb-2 hover:text-primary-2"
                            >
                                Learn
                            </Link>
                            <Link
                                href={"/practice"}
                                className="mb-2 hover:text-primary-2"
                            >
                                Practice
                            </Link>
                            <Link
                                href={"/compete/full-length"}
                                className="mb-2 hover:text-primary-2"
                            >
                                Mock tests
                            </Link>
                            <Link
                                href={"/compete/sectional-tests"}
                                className="mb-2 hover:text-primary-2"
                            >
                                Section wise tests
                            </Link>
                            <Link
                                href={"/daily/daily-news"}
                                className="mb-2 hover:text-primary-2"
                            >
                                News
                            </Link>
                        </div>
                        <div className="flex flex-col text-lg text-white">
                            <p className="mb-4 font-bold text-primary-2">
                                Blogs
                            </p>
                            <Link
                                href={"/blogs/announcements"}
                                className={`mb-2 hover:text-primary-2`}
                            >
                                Announcement
                            </Link>
                            <Link
                                href={"/blogs/exam/cat"}
                                className="mb-2 hover:text-primary-2"
                            >
                                Cat Exam
                            </Link>
                            <Link
                                href={"/blogs/colleges/top-business-schools"}
                                className="mb-2 hover:text-primary-2"
                            >
                                Top B-Schools
                            </Link>
                            <Link
                                href={"/"}
                                className="mb-2 hover:text-primary-2"
                            >
                                Articles
                            </Link>
                            <Link
                                href={"/terms-of-use-and-policy"}
                                className="mb-2 hover:text-primary-2"
                            >
                                Terms and Policy
                            </Link>
                            <Link
                                href={"/faq"}
                                className="mb-2 hover:text-primary-2"
                            >
                                FAQ
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex-col">
                    <p className="text-2xl font-semibold text-primary-2">
                        Contact Us
                    </p>

                    {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-8 w-8 text-primary-2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                            />
                        </svg> */}
                    <div className="my-4 flex items-center text-lg font-semibold text-white">
                        <MessageCircle className="mr-2 w-9 text-2xl text-primary-2" />{" "}
                        <p>WhatsApp</p>
                    </div>

                    <div className="my-4 flex items-center text-lg font-semibold text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-8 w-8 text-primary-2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                            />
                        </svg>
                        <div className="ml-4 flex-col">
                            <p>Call</p>
                            <p className="select-none text-sm">9600 121 800</p>
                        </div>
                    </div>
                    <div className="my-4 flex items-center text-lg font-semibold text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-8 w-8 text-primary-2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                            />
                        </svg>

                        <Link
                            href={`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`}
                            className="ml-4 flex-col"
                        >
                            <p>Email</p>
                            <p className="select-none text-sm">
                                info@mockat.com
                            </p>
                        </Link>
                    </div>
                    <div className="my-4 flex max-w-xs items-center text-lg font-semibold text-white">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-12 w-12 text-primary-2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                            />
                        </svg>
                        <div className="ml-4 flex-col">
                            <p>Address</p>
                            <p className="text-sm">
                                Mockat, 4 Thirumoorthy Nagar First Street,
                                Nungambakkam, Chennai - 600034
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="selection:bg-secondary flex w-full items-center justify-between bg-[#424951] px-2 py-2 text-[7px] font-medium text-white md:px-20 md:py-4 md:text-sm">
                <p>
                    &copy; {new Date().getFullYear()}. All Rights Reserved.
                    Mockat Solutions
                </p>
                <div className="flex items-center">
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/mockat.fb/"
                        className="w-6-h-6 hover:border-primary hover:bg-primary cursor-pointer rounded-full border border-white p-3 text-black transition-colors md:mx-2"
                    >
                        <svg
                            className="h-4 w-4 text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                        </svg>
                    </Link>
                    {/* <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/@Mockat"
                        className="w-6-h-6 hover:border-primary hover:bg-primary mx-2 cursor-pointer rounded-full border border-white p-3 text-black transition-colors"
                    >
                        <svg
                            className="h-4 w-4 text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                        </svg>
                    </Link> */}
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/mockat/"
                        className="w-6-h-6 hover:border-primary hover:bg-primary mx-2 cursor-pointer rounded-full border border-white p-3 text-black transition-colors"
                    >
                        <svg
                            className="h-4 w-4 text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />{" "}
                            <rect x="2" y="9" width="4" height="12" />{" "}
                            <circle cx="4" cy="4" r="2" />
                        </svg>
                    </Link>
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/@Mockat"
                        className="w-6-h-6 hover:border-primary hover:bg-primary mx-2 cursor-pointer rounded-full border border-white p-2 text-black transition-colors"
                    >
                        <svg
                            className="h-6 w-6 text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <rect x="3" y="5" width="18" height="14" rx="4" />
                            <path d="M10 9l5 3l-5 3z" />
                        </svg>
                    </Link>
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/mockat.com_/"
                        className="w-6-h-6 hover:border-primary hover:bg-primary mx-2 cursor-pointer rounded-full border border-white p-2 text-black transition-colors"
                    >
                        <svg
                            className="h-6 w-6 text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <rect x="4" y="4" width="16" height="16" rx="4" />{" "}
                            <circle cx="12" cy="12" r="3" />{" "}
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                        </svg>
                    </Link>
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://mockatcatpreparation.quora.com/"
                        className="w-6-h-6 hover:border-primary hover:bg-primary mx-2 cursor-pointer rounded-full border border-white p-2 text-black transition-colors"
                    >
                        <svg
                            className="h-6 w-6 text-white"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <circle cx="12" cy="12" r="9" />
                            <path d="M9 12a3 3 0 1 0 6 0 3 3 0 0 0-6 0z" />
                            <path d="M12 12v3" />
                            <path d="M13.5 13.5l2 2" />
                        </svg>
                    </Link>
                </div>
            </div>
        </Fragment>
    );
};

export default Footer;
