import React, { useState } from "react";
import { getImageUrl } from "@/utils";
import Head from "next/head";
import Link from "next/link";
import Coaches from "./Coaches";
import Faq from "./Faqs";
import Features from "./Features";
import FloatingLinks from "./FloatingLinks";
import Footer from "./Footer";
import HeroSection from "./HeroSection";
import Navbar from "./Navbar";
import Testimonials from "./Testimonials";

const Home = () => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="relative flex h-screen w-full flex-col bg-red-400">
            <Head>
                <title>
                    Best Online CAT coaching Institute: The Ultimate Guide To
                    Success
                </title>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <meta
                    property="og:title"
                    content="Best Online CAT coaching Institute: The Ultimate Guide To Success "
                />
                <meta
                    name="keywords"
                    content="cat 2024 online coaching fees,best online coaching for cat 202
                             4, cat 2024 official website,cat 2024 syllabus,top 10 cat online coaching,Which online coaching institute is best for CAT,CAT 2024 online coaching, Best online cat crash course ,CAT preparation online, Online CAT coaching programs, CAT exam online classes, Best online coaching for CAT 2024, CAT online coaching institutes, Online CAT preparation courses, CAT coaching online platforms, CAT 2024 online test series, Online CAT study materials, CAT online mock tests, CAT online coaching reviews, Online CAT coaching fees, CAT online coaching videos, CAT online coaching websites, CAT online coaching classes, CAT online coaching platforms, Online CAT coaching institutes,  Best CAT online coaching, Online coaching for CAT 2024, CAT online coaching videos, cat Quants practice cat DILR practice,cat Verbal practice,Quant, LR, DI, Verbal, Reading Comprehension,Free Questions for CAT 2024 from all important topics past cat papers, cat 2017,"
                />
                <meta
                    name="description"
                    content="Professional coaching by CAT 99.9+ %ilers and IIM Lucknow graduates. Free Daily Quizzes, Speed Concept Tests, Speed Maths, and Daily reading articles. Enroll Mockat today!"
                />
                <meta
                    property="og:description"
                    content=" Professional coaching by CAT 99.9+ %ilers and IIM Lucknow graduates. Free Daily Quizzes, Speed Concept Tests, Speed Maths, and Daily reading articles. Enroll Mockat today!"
                ></meta>
                <meta property="og:url" content="https://mockat.com/" />
                <meta name="author" content="Mockat" />
            </Head>
            <Navbar visible={visible} setVisible={setVisible} />
            <div
                className={`relative z-40 flex w-full flex-col bg-primary-1 ${
                    visible ? "mt-[73px]" : ""
                }`}
            >
                <HeroSection />
                <Features />
                <Testimonials />
                <Coaches />
                <Faq />
                <FloatingLinks />
                <Footer />
            </div>
        </div>
    );
};

export default Home;
