import { useEffect, useState } from "react";
import { isClient } from "@/utils";

type Props = {
    width: number;
    height: number;
};

const breakpoints = {
    0: "xs",
    640: "sm",
    768: "md",
    1024: "lg",
};

const useBreakpoint = () => {
    const [breakpoint, setBreakPoint] = useState("lg");

    const [windowSize, setWindowSize] = useState<Props>(
        isClient()
            ? {
                  width: window.innerWidth ?? 0,
                  height: window.innerHeight ?? 0,
              }
            : {
                  width: 0,
                  height: 0,
              }
    );

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        if (!isClient()) return;

        window.addEventListener("resize", handleResize);
        handleResize();

        if (0 < windowSize.width && windowSize.width < 640) {
            setBreakPoint(breakpoints[0]);
        }
        if (640 < windowSize.width && windowSize.width < 768) {
            setBreakPoint(breakpoints[640]);
        }
        if (768 < windowSize.width && windowSize.width < 1024) {
            setBreakPoint(breakpoints[768]);
        }
        if (1024 < windowSize.width && windowSize.width < 1280) {
            setBreakPoint(breakpoints[1024]);
        }

        return () => window.removeEventListener("resize", handleResize);
    }, [windowSize.width]);

    return breakpoint;
};

export default useBreakpoint;
