import React, { useMemo } from "react";
import { useGetResponseTypeQuery } from "@/types/generated-types";
import { getImageUrl } from "@/utils";
import Link from "next/link";
import { useRouter } from "next/router";

const Vignesh = getImageUrl("/static/tutor-one-up.png");
const Sanjana = getImageUrl("/static/tutor-two-up.png");

export const Tutors = ({ catPage }: { catPage?: boolean }) => {
    return (
        <div className="flex w-full text-blue-3 lg:px-6">
            <div
                className={`flex h-full w-1/2 flex-col items-center justify-start xl:relative xl:bottom-10 ${
                    catPage ? "relative bottom-8 md:bottom-0" : ""
                }`}
            >
                <img
                    src={Vignesh}
                    alt="Vignesh"
                    className=""
                    width={350}
                    height={100}
                />
                <div className="relative bottom-14 rounded-xl bg-light-blue px-2 py-1 text-center text-blue-3 2md:bottom-20 xl:bottom-24 xl:rounded-4xl xl:px-4 xl:py-5">
                    <p className="font-Inter text-xs font-bold leading-[28px] xl:mb-2  xl:text-25">
                        Vignesh Srinivasan
                    </p>
                    <p className="font-Inter text-[8px] font-semibold xl:text-base">
                        CAT 2023 (Quant) -{" "}
                        <span className="font-extrabold"> 99.99%ile</span>
                    </p>
                    <p className="font-Inter text-[8px] font-semibold xl:text-base">
                        CAT 2022 (Quant) -
                        <span className="font-extrabold">99.99%ile </span>
                    </p>
                </div>
            </div>
            <div className="flex h-full w-1/2 flex-col items-center justify-end pt-4 md:pt-0">
                <img
                    src={Sanjana}
                    alt="Sanjana"
                    className="relative md:top-20 md:z-10"
                    width={350}
                    height={200}
                />
                <div className="relative bottom-16 z-50 rounded-xl bg-light-blue px-2 py-1 text-center text-blue-3 2md:-bottom-2 xl:bottom-8 xl:rounded-4xl xl:px-4 xl:py-5">
                    <p className="font-Inter text-xs font-bold leading-[26px] xl:mb-2  xl:text-25">
                        Sanjana Pani
                    </p>
                    <p className="font-Inter text-[8px] font-semibold xl:text-base">
                        CAT 2023 (Verbal) -{" "}
                        <span className="font-extrabold"> 99.97%ile</span>
                    </p>
                    <p className="font-Inter text-[8px] font-semibold xl:text-base">
                        CAT 2022(Verbal) -
                        <span className="font-extrabold"> 99.96%ile</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

const HeroSection = () => {
    const SpeedLinks = [
        {
            title: "Speed Concepts",
            subtitle: "Translate Concept understanding",
            content: "to High Quant Scores",
            link: "/speed/speed-concept",
        },
        {
            title: "Speed Math",
            subtitle: "Unlimited Speed Math",
            content: "to sharpen your calculations and avoid silly mistakes",
            link: "/speed/speed-math",
        },
        {
            title: "Speed Quant",
            subtitle: "CAT-styled Questions",
            content: "to sharpen your concepts, speed & accuracy",
            link: "/speed/quant",
        },
        {
            title: "Speed Verbal",
            subtitle: "Vocabulary & Grammar",
            content: "to ace MBA entrance tests",
            prevContent: "Improve your ",
            link: "/speed/speed-verbal",
        },
    ];

    const router = useRouter();

    // const { data: responseTypeData } = useGetResponseTypeQuery();

    // const responseType = useMemo(() => {
    //     if (responseTypeData?.cat_percentile_constant) {
    //         const data = responseTypeData?.cat_percentile_constant?.[0]?.value;

    //         const catPercentileConstant = JSON.parse(data);

    //         return catPercentileConstant["response_type"];
    //     }
    // }, [responseTypeData]);

    const handleClick = () => {
        router.push("/cat-result/response-sheet");
    };

    return (
        <div className="w-full bg-white px-4 pb-4 md:pb-10 2md:rounded-none 2md:px-4 lg:px-6 xl:rounded-xl xl:rounded-tr-none xl:px-16 xl:pt-14">
            <div className="flex w-full flex-col justify-between md:flex-row">
                <div className="mt-6 flex flex-col text-blue-3 md:mr-8 md:w-1/2 md:px-4">
                    {/* <div
                        className="w-full cursor-pointer"
                        onClick={handleClick}
                    >
                        <img
                            src={getImageUrl("/static/home-percentile.png")}
                            alt=""
                            className="w-full"
                            width={200}
                            height={100}
                        />
                    </div> */}
                    <p className="flex flex-col text-xl font-bold text-blue-9 sm:flex-row sm:items-center md:leading-48 xl:my-1 xl:text-3xl 2xl:text-4xl">
                        Ready to Conquer
                        <span className="text-2xl sm:pl-1.5 xl:text-4xl xl:leading-58 2xl:text-5xl">
                            CAT 2024?
                        </span>
                    </p>
                    {/* <p className="text-xl font-bold text-blue-9 xl:my-1 xl:text-4xl xl:leading-48">
                        Start Your Journey to a
                        <span className="text-2xl xl:text-5xl xl:leading-58">
                            Top IIM!
                        </span>
                    </p> */}
                    <p className="mt-2 text-xl font-medium xl:mt-4 xl:pb-2 xl:text-4xl">
                        Let the 99.9+%ilers guide you
                    </p>
                    <hr className="my-3 border-blue-4 md:my-0 md:mt-3" />
                    <div className="flex items-center justify-center px-4 md:my-4">
                        {/* <div className="flex items-center">
                            <img
                                src={getImageUrl("/images/play-circle.svg")}
                                width={16}
                                height={16}
                                alt="Play Icon"
                                className="h-4 w-4 md:h-12 md:w-12"
                            />
                            <div className="ml-2 flex flex-col text-[7px] font-normal text-warm-gray md:ml-4 md:text-15">
                                <p>Let us show you </p>
                                <p>how we take a class</p>
                            </div>
                        </div> */}
                        <div className="w-full">
                            <iframe
                                style={{ width: "100%", height: "250px" }}
                                src="https://www.youtube.com/embed/6FTtzZ6Mr5s?si=A6bXYvFZItYGlY79"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className=" flex w-full md:w-1/2">
                    <Tutors />
                </div>
            </div>
            <div className="w-full">
                <p className="relative top-3 z-10 mx-auto w-1/2 rounded-tl-xl rounded-tr-xl bg-blue-3 pb-3 pt-1 text-center font-Inter text-10 font-bold text-white md:w-max md:rounded-tl-18 md:rounded-tr-18 md:px-12 md:pb-4 md:text-xl xl:pt-1">
                    SPEED{" "}
                    <span className="text-[7px] md:text-base">up your</span> CAT
                    Preparation
                </p>
                <div className="relative z-20 flex w-full items-center justify-around rounded-2xl bg-gradient-to-r from-blue-11 via-blue-12 via-55% to-blue-11 to-95% px-1 py-2 text-white 2md:px-4 2md:py-4 xl:px-6 xl:py-3 xl:pt-6">
                    <div className="flex w-full flex-col items-center 2md:flex-col  xl:flex-row xl:py-8">
                        <div className="flex w-full flex-col font-Inter font-normal md:text-xl">
                            <p className="text-base font-bold md:my-1 md:mb-2 md:text-32 md:leading-40">
                                Over 90% of students do not focus on this.
                            </p>
                            <p className="text-10 md:text-xl">
                                Train on Speed to Crack CAT 2024
                            </p>
                            {/* <div className="flex items-center  md:mt-2">
                                <p className="text-10 md:text-xl">
                                    How fast are you?
                                </p>
                            </div> */}
                        </div>
                        <div className="ml-4 mt-4 flex w-full flex-row flex-wrap items-center justify-center md:ml-0 md:flex-row 2md:mr-0 2md:mt-4 2md:flex-wrap 2md:justify-start 2md:gap-2 lg:justify-center xl:mr-12 xl:mt-0 xl:flex-nowrap xl:justify-start xl:gap-0">
                            {SpeedLinks.map((item, index) => {
                                return (
                                    <Link
                                        href={item.link}
                                        key={index}
                                        className="mr-4"
                                    >
                                        <div className="relative mb-4 h-[147px] w-150 flex-col rounded-3xl bg-white bg-opacity-30 shadow-xl md:mb-0 xl:h-full xl:w-200 xl:max-w-200">
                                            <div className="relative flex justify-center py-2">
                                                <img
                                                    src={getImageUrl(
                                                        "/images/bg-white.png"
                                                    )}
                                                    width={140}
                                                    height={120}
                                                    className="h-[126px] w-[140px] xl:h-168 xl:w-[180px] xl:max-w-none"
                                                    alt=""
                                                />
                                                <div className="absolute left-0 top-4 p-1 xl:left-4 xl:w-167 xl:p-1">
                                                    <p className="mb-1 w-full whitespace-nowrap text-center text-base font-semibold text-blue-3 xl:mb-3 xl:text-xl">
                                                        {item.title}
                                                    </p>

                                                    <p className="mb-2 w-full px-2.5 text-center text-10 font-normal text-blue-3 xl:text-xs">
                                                        <span className="font-semibold">
                                                            {item.subtitle}{" "}
                                                            &nbsp;
                                                        </span>
                                                        {item.content}
                                                    </p>
                                                </div>
                                            </div>
                                            <button className="absolute bottom-3 left-4 rounded-xl bg-blue-9 px-2 py-1 text-xs font-medium text-white shadow-box-2 xl:bottom-2 xl:rounded-3xl xl:px-3.5 xl:py-1 xl:text-sm">
                                                Start Now
                                            </button>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
