import React from "react";
import Link from "next/link";

const NavbarPopper = () => {
    return (
        <div className="fixed left-1/2 top-20 z-50 my-4 flex w-10/12 max-w-full -translate-x-1/2 items-start rounded-xl  border-4 border-indigo-3 bg-primary-1 px-20 py-12">
            <div className="mr-16 flex flex-col">
                <p className="mb-2 font-semibold text-indigo-7">Speed</p>
                <Link
                    href="speed/speed-math"
                    className="mb-3 cursor-pointer text-xl font-bold text-white outline-none"
                >
                    Speed Math
                </Link>
                <Link
                    href="speed/speed-verbal"
                    className="mb-3 cursor-pointer text-xl font-bold text-white outline-none"
                >
                    Speed Vocab
                </Link>
                <Link
                    href="speed/quant"
                    className="mb-3 cursor-pointer text-xl font-bold text-white outline-none"
                >
                    Speed Quant
                </Link>
                <Link
                    href="speed/speed-concept"
                    className="mb-3 cursor-pointer text-xl font-bold text-white outline-none"
                >
                    Speed Concepts
                </Link>
            </div>
            <div className="mr-16 flex flex-col">
                <p className="mb-2 font-semibold text-indigo-7">Daily</p>
                <Link
                    href={"/daily/daily-quiz"}
                    className="mb-3 cursor-pointer text-xl font-bold text-white"
                >
                    Daily Quizzes
                </Link>
                <Link
                    href={"/daily/daily-news"}
                    className="mb-3 cursor-pointer text-xl font-bold text-white"
                >
                    Daily News
                </Link>
                <Link
                    href={"/daily/daily-pov"}
                    className="mb-3 cursor-pointer text-xl font-bold text-white"
                >
                    Daily PoV
                </Link>
            </div>
            <div className="mr-2 flex flex-col">
                <Link
                    href={"/learn/quantitative-ability"}
                    className="mt-4 flex w-full cursor-pointer flex-col items-start gap-4 text-xl font-bold text-white md:flex-row"
                >
                    <span className="w-26">Learn</span>
                    <p className="mt-1 w-4/5 text-base font-semibold text-indigo-7 md:w-5/6">
                        600+ hours of Concept-driven videos by CAT toppers
                    </p>
                </Link>

                <Link
                    href={"/practice/quantitative-ability/"}
                    className="mt-4 flex w-full cursor-pointer flex-col items-start gap-4 text-xl font-bold text-white md:flex-row"
                >
                    <span className="w-26">Practice</span>
                    <p className="mt-1 w-4/5 text-base font-semibold text-indigo-7 md:w-5/6">
                        2000+ Topic-wise questions with Easy, Medium, Hard
                        difficulty
                    </p>
                </Link>

                <Link
                    href={"/compete/full-length"}
                    className="mt-4 flex w-full cursor-pointer flex-col items-start gap-4 text-xl font-bold text-white md:flex-row"
                >
                    <span className="w-26">Compete</span>
                    <p className="w-4/ mt-1 text-base font-semibold text-indigo-7 md:w-5/6">
                        80+ Mocks and Sectional tests with Video solutions
                    </p>
                </Link>
            </div>
        </div>
    );
};

export default NavbarPopper;
