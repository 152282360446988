import { useMemo } from "react";
import { useGetRandomQuestionQuery } from "./operations/operations.generated";

const useGetRandomQuestion = (quizId: number) => {
    const { data, refetch } = useGetRandomQuestionQuery({
        variables: {
            quizId: quizId,
        },
    });

    const question = useMemo(() => {
        if (data?.get_random_question) {
            const question = {
                id: data.get_random_question.question_id,
                name: data.get_random_question.quiz_question?.name,
                options: data.get_random_question.quiz_question?.options,
                answer: data.get_random_question.quiz_question?.options.find(
                    (option) => option.is_correct
                )?.name,
                type: data?.get_random_question?.quiz_question?.question_type,
            };

            return question;
        }
    }, [data]);

    return { question, refetchQuestion: refetch };
};

export default useGetRandomQuestion;
