import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { FAQ } from "@/constants/faqs";

const Faq = () => {
    return (
        <div className="w-full bg-white py-8">
            <h2 className="mb-6 text-center text-xl font-semibold">FAQs</h2>
            <Accordion type="single" collapsible className="mb-4 w-full px-14">
                {FAQ.map((item, ind) => (
                    <AccordionItem value={`item-${ind}`} key={ind}>
                        <AccordionTrigger>{item.question}</AccordionTrigger>
                        <AccordionContent>{item.answer}</AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};

export default Faq;
