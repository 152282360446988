import React from "react";
import { getImageUrl } from "@/utils";
import DailyModulesLinks from "./DailyModulesLinks";
import FeatureItems from "./FeatureItems";
import SpeedModulesLinks from "./SpeedModulesLinks";

const Features = () => {
    return (
        <div className="w-full rounded-tl-4xl bg-[#131047] px-4 md:bg-gradient-to-r  md:from-[#20007E]  md:to-[#14014A] md:pb-8 md:pt-4 2md:mt-0 2md:rounded-tl-none 2md:px-4 lg:px-6  xl:mt-12 xl:px-16">
            <p className="mb-2 text-center font-Inter text-[47px] font-bold text-white md:mb-8 md:text-48">
                Features
            </p>
            <div className="mb-5 flex w-full flex-col items-stretch justify-around md:flex-row 2md:flex-wrap xl:flex-nowrap">
                {/* <div className="relative flex h-[530px] w-full flex-col items-center rounded-4xl bg-white bg-opacity-35 p-4 2md:h-[530px] 2md:w-[30%] xl:h-auto xl:w-1/4"> */}
                {/* <div className="relative z-40 h-max w-full rounded-4xl bg-white p-4 text-32 font-bold text-blue-9 shadow-md ">
                        <div className="flex justify-between">
                            <p>
                                FREE <br /> Lessons
                            </p>
                            <img
                                src={getImageUrl(
                                    "/images/arrow-circle-right.svg"
                                )}
                                alt="Arrow Right"
                                className="ml-2"
                                width={25}
                                height={25}
                            />
                        </div>
                        <p className="pt-4 text-base font-bold text-blue-3 xl:text-base">
                            All Classes by 99.9+ %ilers
                        </p>
                    </div> */}
                {/* <div className="absolute left-4 right-4 top-[30%] z-30 rounded-b-4xl bg-pale-green px-5 pb-3 pt-16 text-center shadow-box-2 md:bottom-[43%] md:top-auto">
                        <p className="mb-2 font-Inter text-2xl font-bold text-blue-3">
                            Quant <span className="text-xl">by</span>
                        </p>
                        <p className="font-Inter text-base font-bold text-blue-3">
                            Vignesh Srinivasan
                        </p>
                    </div>
                    <div className="absolute left-4 right-4 top-[55%] z-20 rounded-b-4xl bg-pale-green px-5 pb-3 pt-10 text-center shadow-box-2 md:bottom-[24%] md:top-auto">
                        <p className="mb-2 font-Inter text-2xl font-bold text-blue-3">
                            VARC <span className="text-xl">by</span>
                        </p>
                        <p className="font-Inter text-base font-bold text-blue-3">
                            Sanjana Pani
                        </p>
                    </div>
                    <div className="flex h-full w-full items-end rounded-b-4xl bg-pale-green px-5 pb-3 pt-10 shadow-box-2">
                        <div className="w-full text-center">
                            <p className="mb-2 font-Inter text-2xl font-bold text-blue-3">
                                DILR <span className="text-xl">by</span>
                            </p>
                            <p className="font-Inter text-base font-bold text-blue-3">
                                Vignesh Srinivasan
                            </p>
                        </div>
                    </div> */}
                {/* </div> */}
                <div className="mb-10 flex flex-wrap">
                    <FeatureItems />
                    {/* <SpeedModulesLinks /> */}
                </div>
            </div>
            <DailyModulesLinks />
        </div>
    );
};

export default Features;
