/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/template-indent */
/* eslint-disable padding-line-between-statements */
/* eslint-disable @typescript-eslint/ban-ts-comment */
//@ts-nocheck
/* @ts-nocheck eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import * as Types from '../../types/generated-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@/utils/apolloReactHooks';
const defaultOptions = {} as const;
export type GetRandomQuestionQueryVariables = Types.Exact<{
  quizId: Types.Scalars['Int']['input'];
}>;


export type GetRandomQuestionQuery = { __typename?: 'Query', get_random_question?: { __typename?: 'quiz_quiz_questions', question_id: number, quiz_question: { __typename?: 'quiz_question', name: string, question_type: string, options: Array<{ __typename?: 'quiz_option', is_correct: boolean, id: number, name: string }> } } | null };

export type AddUserActivityDataMutationVariables = Types.Exact<{
  object: Types.User_Activities_Insert_Input;
  constraint: Types.User_Activities_Constraint;
}>;


export type AddUserActivityDataMutation = { __typename?: 'Mutation', insert_user_activities_one?: { __typename?: 'user_activities', id: any } | null };


export const GetRandomQuestionDocument = gql`
    query getRandomQuestion($quizId: Int!) {
  get_random_question(args: {quiz_id_from_args: $quizId}) {
    question_id
    quiz_question {
      name
      question_type
      options {
        is_correct
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetRandomQuestionQuery__
 *
 * To run a query within a React component, call `useGetRandomQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRandomQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRandomQuestionQuery({
 *   variables: {
 *      quizId: // value for 'quizId'
 *   },
 * });
 */
export function useGetRandomQuestionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRandomQuestionQuery, GetRandomQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRandomQuestionQuery, GetRandomQuestionQueryVariables>(GetRandomQuestionDocument, options);
      }
export function useGetRandomQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRandomQuestionQuery, GetRandomQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRandomQuestionQuery, GetRandomQuestionQueryVariables>(GetRandomQuestionDocument, options);
        }
export type GetRandomQuestionQueryHookResult = ReturnType<typeof useGetRandomQuestionQuery>;
export type GetRandomQuestionLazyQueryHookResult = ReturnType<typeof useGetRandomQuestionLazyQuery>;
export type GetRandomQuestionQueryResult = Apollo.QueryResult<GetRandomQuestionQuery, GetRandomQuestionQueryVariables>;
export const AddUserActivityDataDocument = gql`
    mutation AddUserActivityData($object: user_activities_insert_input!, $constraint: user_activities_constraint!) {
  insert_user_activities_one(
    object: $object
    on_conflict: {constraint: $constraint, update_columns: [updated_at]}
  ) {
    id
  }
}
    `;
export type AddUserActivityDataMutationFn = Apollo.MutationFunction<AddUserActivityDataMutation, AddUserActivityDataMutationVariables>;

/**
 * __useAddUserActivityDataMutation__
 *
 * To run a mutation, you first call `useAddUserActivityDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserActivityDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserActivityDataMutation, { data, loading, error }] = useAddUserActivityDataMutation({
 *   variables: {
 *      object: // value for 'object'
 *      constraint: // value for 'constraint'
 *   },
 * });
 */
export function useAddUserActivityDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserActivityDataMutation, AddUserActivityDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddUserActivityDataMutation, AddUserActivityDataMutationVariables>(AddUserActivityDataDocument, options);
      }
export type AddUserActivityDataMutationHookResult = ReturnType<typeof useAddUserActivityDataMutation>;
export type AddUserActivityDataMutationResult = Apollo.MutationResult<AddUserActivityDataMutation>;
export type AddUserActivityDataMutationOptions = Apollo.BaseMutationOptions<AddUserActivityDataMutation, AddUserActivityDataMutationVariables>;