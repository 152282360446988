import { useEffect } from "react";
import { User_Activities_Constraint } from "@/types/generated-types";
import { useAddUserActivityDataMutation } from "./operations/operations.generated";


const useAddUserActivity = ({
    type,
    quizID,
    learnCategoryID,
}: {
    type: "practice" | "learn" | "compete"|"speed_concepts";
    quizID?: number|null;
    learnCategoryID?: number;
}) => {
    const [addUserActivity] = useAddUserActivityDataMutation();

    useEffect(() => {
        if (quizID) {
            addUserActivity({
                variables: {
                    object: {
                        quiz_id: quizID,
                        type,
                    },
                    // constraint: "user_activities_quiz_id_type_user_id_key",
                    constraint:
                        User_Activities_Constraint.UserActivitiesQuizIdTypeUserIdKey,
                },
            });
        }

        if (learnCategoryID) {
            addUserActivity({
                variables: {
                    object: {
                        learn_category_id: learnCategoryID,
                        type,
                    },
                    constraint:
                        User_Activities_Constraint.UserActivitiesLearnCategoryIdUserIdTypeKey,
                },
            });
        }
    }, [type, quizID, learnCategoryID]);
};

export default useAddUserActivity;