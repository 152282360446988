export const FAQ = [
    {
        question: "What is Mockat?",
        answer: "Mockat is a premier online platform offering specialized coaching for CAT, XAT, and other MBA entrance examinations. We provide expert guidance to help you excel in your MBA entrance tests with a focus on both foundational learning and advanced test-taking strategies.",
    },
    {
        question: "Who are the instructors at Mockat?",
        answer: "Our instructors are top-percentile scorers in their respective sections of the MBA entrance exams. They include experienced educators like Sanjana Ma’am and Vignesh Sir, who bring proven techniques and personalized coaching to help you achieve your best score.",
    },
    {
        question: "How does Mockat prepare students for the CAT exam?",
        answer: "Mockat prepares students through a comprehensive approach that includes live sessions, interactive guided sessions, daily practice, and access to extensive online resources. Our methodologies, such as the Author's PoV and 6-8-8 strategy, are tailored to enhance your performance effectively.",
    },
    {
        question: "Can I try a demo class before enrolling?",
        answer: "Yes, Mockat offers a free demo class for new students. This session helps you understand our teaching methods and interact with our faculty. You can sign up for a demo class through our website or contact us directly for more details.",
    },
    {
        question: "What types of courses does Mockat offer?",
        answer: "Mockat offers a range of courses tailored for different needs, including full-time courses for CAT and XAT, sectional courses for specific areas like Verbal Ability or Quant, and short-term intensive courses aimed at brushing up skills just before the exams.",
    },
    {
        question: "How do I access Mockat’s courses?",
        answer: "Courses can be accessed through our website. Once you register and enroll in a course, you will receive login credentials to access live classes, recorded sessions, practice materials, and tests all available on our user-friendly online platform.",
    },
    {
        question:
            "What makes Mockat different from other CAT preparation platforms?",
        answer: "Mockat stands out due to our focus on individual student performance, cutting-edge teaching techniques, and mentorship from instructors who have themselves excelled at these exams. Our personalized attention ensures that every student can identify and work on their areas of improvement.",
    },
    {
        question: "Does Mockat offer material for self-study?",
        answer: "Yes, alongside live coaching, Mockat provides a wealth of self-study materials including practice tests, question banks, video lessons, and revision notes that are accessible anytime on our platform.",
    },
    {
        question: "How can I track my progress at Mockat?",
        answer: "Mockat offers a personalized dashboard that tracks your progress through practice tests and mock exams. Feedback and analysis help you understand your strengths and weaknesses to focus your studies more effectively.",
    },
    {
        question: "What are the fees for Mockat’s courses?",
        answer: "Our course fees vary depending on the type and duration of the course selected. For detailed information on course fees and any ongoing discounts or offers, please visit our 'Courses' page or contact our customer service team.",
    },
];
