import { useTimer } from "react-timer-hook";


const useGetTimer = ({
    timer,
    onExpire,
}: {
    timer: number;
    onExpire: () => void;
}) => {
    const addSeconds = (date: Date, seconds: number) => {
        date.setSeconds(date.getSeconds() + seconds);

        return date;
    };

    const date = new Date();

    const newDate = addSeconds(date, timer);

    const { seconds, minutes, pause, resume, restart } = useTimer({
        expiryTimestamp: newDate,
        onExpire: onExpire,
    });

    const restartTimer = (time: number) => {
        const date = new Date();

        const newDate = addSeconds(date, time);

        restart(newDate);
    };

    const totalSeconds = seconds + minutes * 60;

    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;

    return {
        formattedTime,
        seconds,
        pause,
        resume,
        restartTimer,
        totalSeconds,
    };

    // return seconds + minutes * 60;
};

export default useGetTimer;