const totalSeconds = (interval: string) => {
    const parts = interval?.split(":");

    const hours = Number.parseInt(parts?.[0]);

    const minutes = Number.parseInt(parts?.[1]);

    const seconds = Number.parseInt(parts?.[2]);

    return hours * 60 * 60 + minutes * 60 + seconds;
};

const convertIntervalToTime = (interval: string) => {
    const parts = interval?.split(":");

    const hours = Number.parseInt(parts?.[0]);

    const minutes = Number.parseInt(parts?.[1]);

    const seconds = Number.parseInt(parts?.[2]);

    let result = "";

    if (hours > 0) {
        result += hours + " hr";
    }

    if (minutes > 0) {
        if (result.length > 0) {
            result += " ";
        }
        result += minutes + " min";
    }

    if (seconds > 0) {
        if (result.length > 0) {
            result += " ";
        }
        result += seconds + " sec";
    }

    return result === "" ? "-" : result;
};

const useConvertIntervalToTime = () => {
    return { convertIntervalToTime, totalSeconds };
};

export default useConvertIntervalToTime;
