import React, { useState } from "react";
import { getImageUrl } from "@/utils";
import { Switch } from "@headlessui/react";
import Link from "next/link";

const FeatureItems = () => {
    const data = [
        {
            bgImage: getImageUrl("/images/bg-white.png"),
            title: "Comprehensive Lessons",
            subTitle: "150+ hours of Video Lessons and Text Lessons",
            link: "/learn/quantitative-ability",
        },
        {
            bgImage: getImageUrl("/images/live-classes-bg.png"),
            title: "Live Classes",
            subTitle: "All Classes by 99.9+%ilers",
            link: "/learn/live-classes",
        },
        {
            bgImage: getImageUrl("/images/free-mocks-bg.png"),
            title: "25+ CAT Mock Tests",
            subTitle: "Take a free test TODAY",
            link: "/compete/full-length",
        },
        {
            bgImage: getImageUrl("/images/quiz-bg.png"),
            title: "Quiz of The Day",
            subTitle: " Sharpen your skills on the DAILY",
            link: "/daily/daily-quiz",
        },
    ];

    const [checked, setChecked] = useState(true);

    return (
        <div className="mb-2 mt-10 flex w-full flex-row flex-wrap items-center justify-center gap-4 md:flex-row 2md:flex-wrap 2md:justify-between 2md:gap-0 lg:justify-center xl:flex-nowrap xl:justify-between xl:gap-0">
            {data.map((item, index) => (
                <Link href={item.link} key={index}>
                    <div className="relative h-[160px] w-[160px] flex-col rounded-3xl bg-white bg-opacity-35 p-2.5 shadow-xl md:h-auto md:w-max  md:first:mr-0 lg:first:mr-8">
                        <div className="relative flex justify-center">
                            <img
                                src={item.bgImage}
                                width={150}
                                height={146}
                                className=" h-[146px] w-[150px]  md:h-auto md:w-[230px] md:max-w-none"
                                alt=""
                            />
                            <div className="absolute left-4 top-2  p-1 px-2 font-Inter md:left-4 md:top-4">
                                <p className="mb-1 w-[82%] break-words text-sm font-bold text-blue-3 sm:text-xl md:text-28 md:leading-38">
                                    {item.title}
                                </p>

                                <p className="mb-2 w-[8%0] break-words text-[7px] font-normal text-blue-3 md:text-xs">
                                    {item.subTitle}
                                </p>
                                {/* {item.title === "Live Classes" && (
                                    <p className="text-[7px] font-bold text-blue-3 md:text-xs">
                                        VARC, 7:30 PM TODAY
                                    </p>
                                )} */}
                            </div>
                        </div>
                        <img
                            src={getImageUrl("/images/arrow-circle-right.svg")}
                            width={16}
                            height={16}
                            alt="Arrow Right"
                            className="absolute bottom-5 right-8 ml-2 h-4 w-4 md:bottom-8 md:right-10 md:h-[25px] md:w-[25px]"
                        />
                        {/* {item.title === "Live Classes" ? (
                            <Switch
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                className={`${
                                    checked
                                        ? "bg-pale-green "
                                        : "bg-cooxlray-400 border-2 border-gray-2"
                                } absolute bottom-1 left-6 z-20 inline-flex h-7 w-[50px] items-center rounded-full md:bottom-2 md:left-4`}
                            >
                                <span
                                    className={`${
                                        checked
                                            ? "translate-x-5 "
                                            : "-translate-x-1 "
                                    } flex h-7 w-7 transform items-center rounded-full bg-white transition`}
                                ></span>
                            </Switch>
                        ) : ( */}
                        <button className="absolute bottom-2  left-4 rounded-xl bg-white px-2 py-1 text-[10px] font-semibold text-blue-3 shadow-md sm:left-8 sm:text-xs md:bottom-2 md:left-4 md:rounded-3xl md:px-3.5 md:py-1 md:text-sm">
                            JOIN NOW
                        </button>
                        {/* )} */}
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default FeatureItems;
